body {
  min-height: 100vh;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.z-index-1 {
  z-index: 100;
}

.z-index-2 {
  z-index: 101;
}

.z-index-3 {
  z-index: 102;
}